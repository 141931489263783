import info from '@amo/core/assets/images/icons/infos_conducteur.png';
import yousign from '@amo/core/assets/images/logos/yousign.png';
import { BulleAide, Question } from '@amo/core/components/containers';
import { Text } from '@amo/core/components/forms';
import { validatePhone } from '@amo/core/utils/validateField';
import _ from 'lodash';
import { useState } from 'react';
import { Field } from 'redux-form';
import Ekomi from './Ekomi/Ekomi';
import PopinOpinionsAssurances from './PopinOpinionsAssurances/PopinOpinionsAssurances';
import './signature.scss';
import TrustPilot from "./TrustPilot/TrustPilot.jsx";

const Signature = (props) => {
    const { data, url, idDevis, apporteur1, changeValue } = props;
    const [receiptBySms, setReceiptBySms] = useState(true);
    const [phoneSign, setPhoneSign] = useState(null);
    const [showPopin, setShowPopin] = useState(true);

    const reformatPhone = async (value) => {
        let newValue = value;

        if (value.replace(/\s+/g, '').length > 10) {
            newValue = value.substring(0, value.length - 1);
        }

        setPhoneSign(newValue.replace(/\s+/g, ''));
    };

    const renderPopin = () => {
        const str = String(idDevis)
        let lastChar = str.charAt(str.length - 1);

        if (_.includes(['0', '1', '2'], lastChar)){
            return <PopinOpinionsAssurances {...props} setShowPopin={setShowPopin} />
        } else if (_.includes(['3', '4'], lastChar)){
            return <Ekomi idDevis={idDevis} />
        } else if (_.includes(['5', '6', '7', '8', '9'], lastChar)){
            return <TrustPilot setShowPopin={setShowPopin} />
        }
    }

    return (
        <div id={'etape-signature'}>
            {showPopin && apporteur1.codeBelair === '43397' && renderPopin() }
            <div className={'row justify-content-center'}>
                <div className={'col-lg-6 col-md-6 col-sm-6 col-12 my-2'}>
                    <div className="alert alert-success shadow bg-white text-success d-block border-0 f-15 text-start">
                        <strong className={'f-16'}>Votre paiement a bien été effectué !</strong>
                    </div>
                </div>
            </div>
            <Question loader icon={info} title={'Signature électronique'}>
                <div
                    className={
                        'd-flex justify-content-between align-items-center offset-lg-3 col-lg-6 col-md-6 col-sm-6 col-12 my-2'
                    }
                >
                    <p className={'f-16 fw-bold'}>Avec notre partenaire</p>
                    <img src={yousign} alt={'Yousign'} />
                    <small>Signature sécurisée</small>
                </div>

                <div className={'row justify-content-center'}>
                    <div className={'col-lg-6 col-md-6 col-sm-6 col-12 my-2'}>
                        <Field
                            label={'N° de téléphone mobile'}
                            component={Text}
                            type={'tel'}
                            typeFormat={'tel'}
                            name={'DemandeContrat[SignaturePhone]'}
                            placeholder={'Exemple : 02 38 53 02 38'}
                            validate={[validatePhone]}
                            onChange={(e) => reformatPhone(e.target.value)}
                            required
                        />
                        <small className={'text-start d-block'}>
                            Vous y recevrez votre code de validation pour signer votre contrat
                        </small>
                    </div>
                </div>

                <a
                    href={`${url}&method=${receiptBySms ? 'sms' : 'email'}&customer_${
                        receiptBySms ? 'phone' : 'email'
                    }=${
                        receiptBySms
                            ? _.get(data, 'DemandeContrat[SignaturePhone]')
                            : _.get(data, 'DemandeContrat[SignatureEmail]')
                    }`}
                    className={`btn btn-primary medium-rounded my-4 ${
                        !_.get(data, 'DemandeContrat[SignaturePhone]') && 'disabled'
                    }`}
                >
                    Signer mes documents
                </a>

                <BulleAide
                    icon={info}
                    title={"La signature électronique c'est quoi ?"}
                    text={`La signature électronique est, pour un document numérique, l’équivalent de la signature manuscrite. En vertu du décret n°2001-272 du 30 mars 2001, elle a la même valeur légale qu’une signature manuscrite.<br/>Le client signe numériquement son contrat de manière totalement sécurisée et il est garanti par un tiers de confiance.`}
                />
            </Question>
        </div>
    );
};

Signature.defaultProps = {};

Signature.propTypes = {};

export default Signature;
