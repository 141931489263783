import { APP_URL_APPLICATION } from '@amo/core/constants/constants.js';
import { getStepBySlug } from '../../../../utils/function';
import { FILL } from '../../../actions/app/fill/fill.actions';
import { CODEPROMO, POST_TARIF_RELOAD, setTarif, TARIF, TARIF_RELOAD } from '../../../actions/app/tarif/tarif.actions';
import { setError, setErrorTarif, setLoader, setStep } from '../../../actions/app/ui/ui.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';
import { formatDataForApi } from '../fill/formatDataForApi';
import {analyticsSetEvent} from "../../../actions/core/analytics/analytics.actions.js";
import * as Sentry from '@sentry/react';

export const tarifMiddleware = () => (next) => (action) => {
    next(action);

    const { payload } = action;
    let body = {};
    let nextActions;

    switch (action.type) {
        case POST_TARIF_RELOAD:
            if (payload.body) {
                body = formatDataForApi(payload.body);
            }


            next([
                apiRequest({
                    body: body,
                    method: 'POST',
                    url: payload.data,
                    entity: TARIF_RELOAD,
                    otherData: { codePromo: payload.meta.otherData.codePromo, codePromoValue: payload.meta.otherData.codePromoValue},
                }),
                setLoader({ state: true, entity: payload.meta.otherData.codePromo ? CODEPROMO : TARIF }),
            ]);
            break;

        case `${TARIF_RELOAD} ${API_SUCCESS}`:
            nextActions = [
                setTarif({ data: payload.data.data.Tarif }),
                setError({ state: false, entity: action.payload.meta.otherData.codePromo ? CODEPROMO : TARIF }),
                setLoader({ state: false, entity: action.payload.meta.otherData.codePromo ? CODEPROMO : TARIF }),
            ];
            if (payload.data.data.Tarif.FeuTricolore === 'Orange') {
                nextActions.push(setStep({ step: getStepBySlug('recontact') }));
            }

            if (payload.data.data.Tarif.FeuTricolore === 'Rouge') {
                nextActions.push(setStep({ step: getStepBySlug('vos-besoins') }));
            }
            if(action.payload.meta.otherData.codePromo && action.payload.meta.otherData.codePromoValue){
                nextActions.push(analyticsSetEvent({event: 'promoAttempt', datas: {
                    'promoAttempt': {
                        'promoCode' : action.payload.meta.otherData.codePromoValue,
                        'status' : payload.data.data.Tarif.CodePromo.Eligible ? 'Réussite' : 'Echec',
                    }
                }}));
            }
            next(nextActions);
            break;

        case `${TARIF_RELOAD} ${API_ERROR}`:
            console.log('TARIF_RELOAD API_ERROR', payload);
            next([
                analyticsSetEvent({event: 'errorMessage', datas: {
                    'errorMessage': {
                        'label': payload?.data?.response?.data?.error || '', //String : message d'erreur affiché
                    }
                }}),
                setStep({ step: getStepBySlug('vos-besoins') }),
                setErrorTarif({ data: payload?.data?.response?.data?.error || 'Impossible de calculer le tarif' }),
                setNotification({
                    entity: FILL,
                    html: payload?.data?.response?.data?.error || "<h4 style='color: #004161;'>Les informations que vous avez saisies ne nous permettent pas de vous proposer un tarif en ligne.</h4><br/><p style='color: #004161;'>Si c'est une erreur de saisie, vous pouvez modifier les informations declarées sur les étapes précédentes</p>",
                    icon: 'error',
                    confirmButtonText: "J'ai compris",
                    allowOutsideClick: false,
                    successFunction: () => {
                        if (payload?.data?.response?.data?.code === 444) {
                            window.localStorage.clear();
                            window.location.reload();
                            window.location.href = APP_URL_APPLICATION;
                        }
                    },
                }),
                setError({ state: true, entity: action.payload.meta.otherData.codePromo ? CODEPROMO : TARIF }),
                setLoader({ state: false, entity: action.payload.meta.otherData.codePromo ? CODEPROMO : TARIF }),
            ]);
            break;

        default:
            return null;
    }
};
